import { Spacer } from '../../components/ui/spacer.tsx'
import { HomeSearchInput } from './homeSearchInput.tsx'
import { useMatches, useNavigate } from '@remix-run/react'
import { type ReactNode, useEffect } from 'react'
import { sendEventCategory } from '../analytics/connectif-events.client.ts'
import { useConnectifContext } from '../analytics/ConnectifProvider.tsx'
import { Icon } from '~/components/ui/icons/icon.tsx'
import { Separator } from '~/components/ui/separator.tsx'
import Filter from '~/features/home/filter.tsx'
import useFilters from './useHomeFilters.tsx'
import { cn } from '~/utils/misc.tsx'

interface Props {
  variant: 'professionals' | 'services'
  resultsNumber: number
  totalItems: number
  categoriesSelectData: {
    name: string
    slug: string | null
    id: number
  }[]
  topicsSelectData: {
    name: string
    slug: string | null
    id: number
  }[]
  children: ReactNode
}

export function ProfessionalList({
  variant,
  resultsNumber,
  categoriesSelectData,
  topicsSelectData,
  children,
  totalItems,
}: Props) {
  const navigate = useNavigate()
  const matches = useMatches()
  const { entityInfo } = useConnectifContext()

  const rootSlug = matches[matches.length - 1].params.rootSlug

  const {
    filters,
    searchParams,
    handleSearchChange,
    resetFilters,
    ...otherHandlers
  } = useFilters()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (filters.categories.length) {
        filters.categories.forEach(category => {
          sendEventCategory(category, entityInfo)
        })
      }
    }
  }, [rootSlug])

  return (
    <div className="relative" id="root">
      <div className="items-center justify-between gap-4 xl:flex">
        {/* Add switch between professionals */}
        <div className="flex items-center justify-between">
          <div className="flex w-[300px] items-center justify-center space-x-3 rounded-full bg-white px-4 py-3 shadow-md">
            <div
              onClick={() => navigate(`/servicios?${searchParams}`)}
              className="flex cursor-pointer items-center space-x-2"
            >
              <Icon
                name="star-filled"
                className="text-secondary"
                size="drawer"
              />
              <p
                className={cn(
                  `text-sm`,
                  variant === 'services' ? 'font-bold' : '',
                )}
              >
                Servicios
              </p>
            </div>
            <Separator className="h-[30px]" orientation="vertical" />
            <div
              onClick={() => navigate(`/profesionales?${searchParams}`)}
              className="flex cursor-pointer items-center space-x-2"
            >
              <p
                className={cn(
                  `text-sm`,
                  variant === 'professionals' ? 'font-bold' : '',
                )}
              >
                Profesionales
              </p>
              <Icon name="profile" className="text-secondary" size="drawer" />
            </div>
          </div>
          <div className="block sm:hidden">
            <Filter
              categoriesSelectData={categoriesSelectData}
              topicsSelectData={topicsSelectData}
              resultsNumber={resultsNumber}
              totalItems={totalItems}
              filters={filters}
              resetFilters={resetFilters}
              handlers={otherHandlers}
            />
          </div>
        </div>
        <div className="mt-4 flex w-full flex-col items-center gap-2 sm:flex-row lg:gap-5 xl:mt-0 xl:justify-end">
          <HomeSearchInput
            variant={variant}
            search={filters.search}
            handleSearchChange={handleSearchChange}
          />
          <div className="hidden sm:block">
            <Filter
              categoriesSelectData={categoriesSelectData}
              topicsSelectData={topicsSelectData}
              resultsNumber={resultsNumber}
              totalItems={totalItems}
              filters={filters}
              resetFilters={resetFilters}
              handlers={otherHandlers}
            />
          </div>
        </div>
      </div>
      <Spacer size="4xs" />
      {children}
    </div>
  )
}
