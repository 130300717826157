import {
  type DataFunctionArgs,
  redirect,
  type MetaFunction,
  json,
} from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import { ActionTypeEnum, SessionTypeEnum } from 'types/enums.ts'
import { HomeProfessionalSessionCard } from '~/components/cards/home-professional-session-card.tsx'
import { MainContainer } from '~/components/layout/main-container.tsx'
import { MainLayout } from '~/components/layout/main-layout.tsx'
import { Icon } from '~/components/ui/icons/icon.tsx'
import { Spacer } from '~/components/ui/spacer.tsx'
import { getUserRole, requireUserId } from '~/features/auth/auth.server.ts'
import { getServicesSlugData } from '~/features/home/getServicesSlugData.server.ts'
import { InfiniteScroll } from '~/features/home/infiniteScroll.tsx'
import { ProfessionalList } from '~/features/home/professional-list.tsx'
import { redirectWithToast } from '~/features/toast/flash-session.server.ts'
import { prisma } from '~/utils/config/db.server.ts'

export const meta: MetaFunction = () => [{ title: 'Miistico' }]

// redirect to user view
export async function loader({ request }: DataFunctionArgs) {
  const userRole = await getUserRole(request)
  let pathname = new URL(request.url).pathname
  if (userRole && !(userRole === 'user' && pathname === '/')) {
    return redirect(`/${userRole}`)
  }

  return getServicesSlugData(request)
}

export const sessionIsVideoCall = (sessionType: string) => {
  return sessionType === SessionTypeEnum.VIDEO_CALL
}

export async function action({ request }: DataFunctionArgs) {
  const userId = await requireUserId(request, 'user')

  const formData = await request.formData()
  const type = formData.get('type')?.toString()

  if (type === ActionTypeEnum.delete) {
    const nickname = formData.get('id')!.toString()
    await prisma.user.update({
      where: {
        id: userId,
      },
      data: {
        favorites: {
          disconnect: {
            nickname,
          },
        },
      },
    })
    return redirectWithToast(``, {
      title: 'Favorito eliminado',
      description: `${nickname} ha sido eliminado de tu lista de favoritos`,
    })
  }

  return json({})
}

export const guestDrawerItems = [
  {
    title: 'Iniciar sesión',
    icon: <Icon size="drawer" className="-ml-[4px]" name="login" />,
    href: '/login',
  },
  {
    title: 'Registrarse',
    icon: <Icon size="drawer" name="register" />,
    href: '/signup',
  },
]

export default function DefaultRoute() {
  const data = useLoaderData<typeof loader>()

  return (
    <MainLayout userRole={data?.userRole as any}>
      <MainContainer>
        <ProfessionalList
          variant="services"
          categoriesSelectData={data.categoriesSelectData}
          topicsSelectData={data.topicsSelectData}
          resultsNumber={data.services.length}
          totalItems={data.totalServices}
        >
          <InfiniteScroll
            items={data.services}
            renderItem={(service: any) => (
              <HomeProfessionalSessionCard
                key={service.id}
                service={service}
                currency={data.currency}
              />
            )}
          />
          <Spacer size="2xs" />
        </ProfessionalList>
      </MainContainer>
      <Outlet />
    </MainLayout>
  )
}
